import React from 'react';
import { withRouter } from 'react-router-dom';
import { Tooltip, Checkbox, Chip } from '@material-ui/core';
import Info from '@material-ui/icons/Info';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import BlockIcon from '@material-ui/icons/Block';
import { getFileSize, getFileIcon, checkPermission } from '../../../utils';
import { imageMedia } from '../../../constants';
import folderIcon from '../../../assets/images/folder_icon.svg'
import sharedIcon from '../../../assets/images/shared_icon.svg'
import collabIcon from '../../../assets/images/img-collab.png';
import LinkIcon from '@material-ui/icons/Link';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import { trackActivity } from '../../../utils/mixpanel';
import { LinkedIssues } from '../../auth/components'
import CircularProgress from '../../../shared_elements/components/CircularProgress';
const FolderCard = ({ history, item, onSelection, selectedItems, conntextMenu, fileIcons, onChangeNav, onShareContent, onOpenRefLnk, openDmTags }) => {
  let denominator = (item?.completeness?.total_count) > 0 ?(item?.completeness?.total_count) :1
  return (
    <div className="folder-card">
      <div className="folder-icon" style={item.type === 'file' && imageMedia.includes(item.extension.toLowerCase()) ? { backgroundImage: "url(" + decodeURIComponent(item.path) + ")", backgroundPosition: 'center', backgroundRepeat: 'no-repaet', backgroundSize: 'contain' } : { background: "#F0F0F0" }}>
        {item.type === 'file' ? <Chip size="small" className="file-status" label={item.status === 'Published' ? 'Published' : 'Unpublished'} /> : null}
        { (checkPermission('records','data_room','DN') || checkPermission('records','data_room','MOV') || checkPermission('records','data_room','D')) ?
          <span className="check-box">
            <Checkbox
              name={`${item.uuid}_${item.type}`}
              id={`${item.uuid}_${item.type}`}
              color="primary"
              className="checkbox-icon"
              size="small"
              checked={selectedItems.ids.find(listItem => (listItem.uuid === item.uuid && listItem.type === item.type)) ? true : false}
              onChange={(e) => { e.stopPropagation(); onSelection(e.target.checked) }}
            />
          </span>:null
        }
        {item.extension && imageMedia.includes(item.extension.toLowerCase()) ?
          <div
            onClick={() => { item.type === 'folder' ? onChangeNav() : window.open(`/records/preview/${item.uuid}`, '_blank'); }}
            style={{ position: 'absolute', top: '0px', bottom: '0', right: '0', left: '30px', cursor: 'pointer' }}>
          </div> :
          <img
            onClick={(e) => { item.type === 'folder' ? onChangeNav() : window.open(`/records/preview/${item.uuid}`, '_blank'); item.type !== 'folder' ? trackActivity('File Previewed', { page_title: 'Dataroom', event_type: 'Preview(Card Layout)', extension: item.extension ? item.extension : '', size: item.size ? item.size : '', file_name: item.name, uuid: item.uuid }) : e.preventDefault() }}
            style={{ width: item.type === 'folder' ? '50px' : '36px' }}
            className="folder-img"
            src={item.type === 'folder' ? folderIcon : getFileIcon(fileIcons, item.extension)} alt={item.extension}
          />
        }
        <div className='data-other-info' style={{display:'flex', justifyContent:'space-between', alignItems:'end'}}>
          <ul className='list-inline'>
            {checkPermission('records','ref_link','R') && item.external_links_count ?
              <li className='list-inline-item' onClick={onOpenRefLnk}>
                <Tooltip title='Reference Link'>
                  <span style={{cursor:'pointer'}} className='flex-centered'><LinkIcon color='primary' fontSize='small' /><span>({item.external_links_count})</span></span>
                </Tooltip>
              </li>:null
            }
            {checkPermission('records','records_issue','R') && item.type === 'file' && item?.issues_count ?
                <li className='list-inline-item'>
                    <LinkedIssues 
                      count={item?.issues_count||0} 
                      recordType={item.type} 
                      recordUuid={item.uuid} 
                      locType='dataroom' 
                      getResponseBack={() => console.log('')}
                    />
                </li>:null
            }
            { checkPermission('records','tags', 'R') ?
              <li className='list-inline-item' onClick={openDmTags} style={{cursor:'pointer'}}>
                {(() => {
                  let toolTipTitle = item?.tags?.length ? `Tags: ${item.tags.map((tag) => tag.name).join(',')}` :'Click to Add Tags'
                  return(
                    <Tooltip title={toolTipTitle} arrow>
                      <span className='flex-centered'><LocalOfferIcon fontSize='small' color='primary' /><span>({item?.tags?.length||0})</span></span>
                    </Tooltip>
                  )
                })()}
              </li>:null
            }
          </ul>
           {item.type == 'file' ? null :
            <Tooltip placement='top' title={
              <div>
                <div>Total Folders: {item?.completeness?.total_count}</div>
                <div> Folders with File(s): {item?.completeness?.complete_count}</div>
              </div>
            } arrow>
              <div className="completnes-percentage" style={{ marginRight: "4px" , float:'right'}}>
                <CircularProgress height={'37px'} value={item?.completeness?.complete_count / denominator} />
              </div>
            </Tooltip>
          }
        </div>
      </div>
      <div className="folder-info">
        <h5 className="flex-centered" onClick={(e) => { item.type === 'folder' ? onChangeNav() : window.open(`/records/preview/${item.uuid}`, '_blank'); item.type !== 'folder' ? trackActivity('File Previewed', { page_title: 'Dataroom', event_type: 'Preview(Card Layout)', extension: item.extension ? item.extension : '', size: item.size ? item.size : '', file_name: item.name, uuid: item.uuid }) : e.preventDefault() }}>
          {item.name.length < (item.type === 'folder' ? 17 : 13) ? item.name : <Tooltip title={item.name} arrow><span>{item.name.slice(0, item.type === 'folder' ? 17 : 13) + '...'}</span></Tooltip>}
        </h5>
        {item.share_enabled ?
          <span className="shared-info">
            <Tooltip title="Copy Shared Link" arrow>
              <img onClick={onShareContent} src={sharedIcon} alt="" />
            </Tooltip>
          </span> : null
        }
        {item.is_folder_collaborated ?
          <span className="shared-info" style={{right:"32px"}}>
            <img style={{ marginLeft: '6px', height: '15px', width: '15px' }} onClick={onShareContent} src={collabIcon} alt="" />
          </span> : null
        }

        {item.type === 'folder' ? <p>Files: {item?.count_details?.file_count ? item?.count_details?.file_count : 0}</p> : <p>Size: {getFileSize(item.size)}</p>}
      </div>
      <span className="context-menu-action">
      {item?.stand_alone_folder_asset != null && item?.stand_alone_folder_slug != null?
       <div style={{display:'flex', alignItems:'end', padding:'0px 0px 5px 3px', cursor:'default'}}> <h1 style={{fontSize:'21px', color:'grey', marginRight:'4px'}}>...</h1>
        <Tooltip title="This is a Folder with Redirection" arrow>
        <Info style={{fontSize:'16px'}}/>
      </Tooltip></div>
        : conntextMenu
        }
      </span>

    </div>
  )
}
export default withRouter(FolderCard);
